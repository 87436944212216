<template>
  <div class="lotteries-schedules-form">
    <a-spin :spinning="loading" :tip="labels.form.loading">
      <ValidationObserver ref="observer">
        <a-form
          :model="form"
          :label-width="80"
          slot-scope="{ validate }"
          @submit.prevent="validate().then(handleSubmit)"
        >
          <a-row
            type="flex"
            justify="space-between"
            :gutter="16"
            :style="{ marginBottom: '15px' }"
          >
            <!-- Title -->
            <a-col :xs="24" :md="12">
              <h2 class="form-title">
                <a-icon :type="isEdit ? 'edit' : 'add'" class="form-icon" />
                <span>{{
                  isEdit ? labels.form.titleEdit : labels.form.titleAdd
                }}</span>
              </h2>
            </a-col>
            <!-- Actions -->
            <a-col :xs="24" :md="12">
              <a-row
                type="flex"
                justify="end"
                :gutter="16"
                :style="{
                  alignItems: 'flex-end',
                  marginBottom: '0px',
                }"
              >
                <a-col v-bind="isMobile && { span: 24 }">
                  <a-button-group>
                    <a-button
                      type="danger"
                      icon="arrow-left"
                      ghost
                      @click="handleCancel"
                      v-bind="isMobile && { block: true }"
                    >
                      <span>{{ labels.form.goBack }}</span>
                    </a-button>
                    <template v-if="canEdit">
                      <a-button
                        type="primary"
                        ghost
                        :loading="submitting"
                        @click="validate().then(() => handleSubmit(true))"
                        v-bind="isMobile && { block: true }"
                      >
                        <span>{{ labels.form.saveAndClose }}</span>
                      </a-button>
                      <a-button
                        type="primary"
                        :loading="submitting"
                        @click="validate().then(handleSubmit)"
                        v-bind="isMobile && { block: true }"
                      >
                        <span>{{ labels.form.save }}</span>
                      </a-button>
                    </template>
                  </a-button-group>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
          <a-row type="flex" justify="start" :gutter="[30, 30]">
            <a-col :span="24">
              <a-card>
                <a-row
                  type="flex"
                  justify="space-between"
                  :gutter="24"
                  :style="{ alignItems: 'center' }"
                >
                  <a-col :xs="24" :sm="24" :md="8">
                    <DatePicker
                      v-model.trim="form.date"
                      :formitem="{ label: labels.form.date.label }"
                      :name="labels.form.date.label"
                      :placeholder="labels.form.date.placeholder"
                      rules="required"
                      :format="DATE_FORMAT.MOMENT_DATE"
                      :readOnly="!canEdit"
                      :disabled-date="disabledDate"
                    />
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="8">
                    <a-checkbox v-model="form.annually" :disabled="!canEdit">
                      {{ labels.form.annually.placeholder }}
                    </a-checkbox>
                  </a-col>
                </a-row>
                <a-row type="flex" justify="start" :gutter="24">
                  <a-col :span="24">
                    <SelectPagination
                      v-model="form.lotteries"
                      :fetchService="fetchLotteries"
                      :placeholder="labels.form.lotteries.placeholder"
                      :labelKey="'name'"
                      :valueKey="'id'"
                      :label="labels.form.lotteries.label"
                      :disabledDefaultFetchOptions="false"
                      :allowClear="true"
                      mode="multiple"
                      :disabled="!canEdit"
                    />
                  </a-col>
                  <a-col :span="24">
                    <Input
                      v-model.trim="form.title"
                      :label="labels.form.title.label"
                      :placeholder="labels.form.title.placeholder"
                      :disabled="!canEdit"
                      :allowClear="true"
                    />
                  </a-col>
                </a-row>
              </a-card>
            </a-col>
          </a-row>
        </a-form>
      </ValidationObserver>
    </a-spin>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import labels from "@/utils/labels";
import Input from "../../../components/core/VeeValidateForm/Input.vue";
import DatePicker from "../../../components/core/VeeValidateForm/DatePicker.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import _ from "lodash";
import locale from "ant-design-vue/es/date-picker/locale/es_ES";
import router from "@/router";
import {
  adminPageTitle,
  hasRoles,
  roles,
  DATE_FORMAT,
} from "../../../utils/utils";
import SelectPagination from "@/components/core/SelectPagination/index.vue";
import moment from "moment";

const initialFormState = {
  date: undefined,
  lotteries: [],
  annually: false,
  title: "",
};

export default {
  name: "LotteryForm",
  components: {
    ValidationObserver,
    DatePicker,
    Input,
    SelectPagination,
  },
  metaInfo: {
    title: adminPageTitle(labels.lotteriesSchedules.title),
    titleTemplate: () => {
      return `${adminPageTitle()} - ${
        router.currentRoute.name.includes("edit")
          ? labels.lotteriesSchedules.pageTitleEditing
          : labels.lotteriesSchedules.pageTitleCreate
      }`;
    },
  },
  data() {
    return {
      locale,
      labels: labels.lotteriesSchedules,
      exception: labels.exception,
      loading: false,
      submitting: false,
      form: _.cloneDeep(initialFormState),
      DATE_FORMAT,
    };
  },
  async created() {
    this.resetForm();
    if (this.$route.params.id) {
      this.fetchData();
    }
  },
  computed: {
    ...mapState("lotteries", ["schedule"]),
    ...mapGetters("adminAuth", ["getLoggedUser"]),
    ...mapState("layout", ["screenWidth"]),
    isMobile() {
      return this.screenWidth < 600;
    },
    isEdit() {
      return !!this.$route.params?.id;
    },
    canEdit() {
      const { accessRoles } = this.getLoggedUser;
      return (
        !this.isEdit ||
        hasRoles(accessRoles, [roles.admin.ROLE_LOTTERIES_CLOSING_SCHEDULE])
      );
    },
    dateApiFormat() {
      return DATE_FORMAT.SERVER_DATE;
    },
  },
  methods: {
    ...mapActions("lotteries", [
      "fetchSchedule",
      "createSchedule",
      "updateSchedule",
      "deleteSchedule",
      "resetForm",
      "fetchLotteries",
    ]),
    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().subtract(1, "day").endOf("day");
    },
    async fetchData() {
      try {
        this.loading = true;
        await this.fetchSchedule(this.$route.params.id);
      } catch (error) {
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.message
        );
        this.$router.push({ name: "admin.lotteries-closing-schedules" });
      } finally {
        this.loading = false;
      }
    },
    async handleSubmit(closeAfterSave = false) {
      if (this.submitting) return;
      this.submitting = true;
      let response = null;
      let editting = this.isEdit;
      let form = _.cloneDeep(this.form);
      try {
        Object.keys(form).forEach((key) => {
          if (
            form[key] &&
            typeof form[key] === "object" &&
            "format" in form[key]
          ) {
            form[key] = form[key].format(this.dateApiFormat);
          } else if (form[key] === "") {
            form[key] = null;
          }
        });
        if (editting) {
          response = await this.updateSchedule(form);
        } else {
          response = await this.createSchedule(form);
        }
        if (response) this.$message.success(response.message);
        if (closeAfterSave) {
          this.$router.push({ name: "admin.lotteries-closing-schedules" });
        } else {
          if (!editting) {
            this.resetForm();
            this.$router.push({
              name: "admin.lotteries-closing-schedules.edit",
              params: { id: response.data.id },
            });
          }
        }
      } catch (error) {
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.message
        );
        this.$log.error(error);
      } finally {
        this.submitting = false;
      }
    },
    handleCancel() {
      this.$router.push({ name: "admin.lotteries-closing-schedules" });
    },
  },
  watch: {
    "$route.params.id"(value, oldValue) {
      if (value && value !== oldValue) {
        this.fetchData();
      }
    },
    schedule: {
      handler(schedule = {}) {
        Object.assign(
          this.form,
          schedule && !_.isEmpty(schedule)
            ? _.cloneDeep({
                ...schedule,
                annually: !!schedule.annually,
                date: this.$moment(schedule.date, DATE_FORMAT.SERVER_DATE),
              })
            : _.cloneDeep(initialFormState)
        );
      },
      deep: true,
    },
  },
  beforeDestroy() {
    this.form = _.cloneDeep(initialFormState);
    this.resetForm();
  },
};
</script>
<style lang="scss">
@import "../../../assets/scss/variable";
.lotteries-schedules-form {
  .form-title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  .form-icon {
    margin-right: 8px;
  }
  .form-button-group {
    margin-top: 16px;
  }
  .lottery {
    font-family: "Montserrat", sans-serif;
    text-align: center;
    padding: 15px 0;
    &-name {
      font-size: 22px;
      font-weight: bold;
    }
    &-joined {
      font-size: 14px;
      color: color(--gray-1);
      span {
        font-weight: bold;
      }
    }
  }
}
.ant-input[disabled],
.ant-time-picker-input[disabled],
.ant-input-number-disabled,
.ant-select-disabled .ant-select-selection {
  background-color: color(--white) !important;
  color: rgba(0, 0, 0, 0.65) !important;
}
.ant-btn-group {
  width: 100%;
}
</style>
